<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { HeadlessDialogPanel } from "#components";

const emit = defineEmits<{
  "update:isOpen": [value: boolean];
}>();

const props = withDefaults(
  defineProps<{
    isOpen?: boolean;
    title?: string;
    class?: HTMLAttributes["class"];

    tonal?: boolean;
    wide?: boolean;
    front?: boolean;

    drawer?: boolean;
    noBg?: boolean;

    big?: boolean;
  }>(),
  {
    drawer: false,
  }
);

const isOpen = useVModel(props, "isOpen", emit, {
  passive: true,
  defaultValue: false,
});

const { currentPalette } = useTheme();
const { isMobile } = useUserDevice();
</script>

<template>
  <Teleport to="body">
    <HeadlessTransitionRoot :show="isOpen" as="template">
      <HeadlessDialog
        class="app-dialog relative text-sm pt-[4.125rem] lg:pt-0"
        :class="[front ? 'z-50' : 'z-40', props.class]"
        @close="isOpen = false"
        v-bind="$attrs"
        static
      >
        <HeadlessTransitionChild as="template">
          <div
            class="app-dialog__overlay fixed inset-0 lg:backdrop-blur-sm cursor-pointer"
            :class="[
              props.drawer
                ? props.noBg
                  ? 'bg-black/80 backdrop-blur-sm'
                  : 'bg-grey'
                : props.tonal
                ? currentPalette.bg
                : 'bg-white lg:bg-black/80',
            ]"
            aria-hidden="true"
          ></div>
        </HeadlessTransitionChild>

        <HeadlessTransitionChild as="template">
          <component
            :is="isMobile ? HeadlessDialogPanel : 'div'"
            class="fixed inset-0 w-screen overflow-y-auto overscroll-none lg:px-10"
            :class="[
              {
                'flex flex-col items-stretch justify-end': drawer,
              },
            ]"
          >
            <!-- Mobile header -->
            <div
              v-if="!drawer"
              class="app-dialog__header sticky top-0 z-10 lg:hidden h-[4.125rem] flex flex-col items-stretch justify-center select-none"
              :class="[props.tonal ? currentPalette.bg : 'bg-white']"
            >
              <div class="app-dialog__header-container container">
                <div
                  class="app-dialog__header-wrapper flex items-center justify-between gap-4"
                >
                  <div
                    class="app-dialog__header-section app-dialog__header-section--start flex items-center gap-4"
                  >
                    <AppHeaderLogo @click.native="isOpen = false" />
                  </div>

                  <div
                    class="app-dialog__header-section app-dialog__header-section--end flex items-center -m-3"
                  >
                    <slot name="header-actions"></slot>

                    <button
                      class="app-dialog__close app-dialog__close--mobile w-12 h-12 flex items-center justify-center"
                      title="Zamknij"
                      @click="isOpen = false"
                    >
                      <Icon name="ns:close-big" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Close button on mobile instead of header when dialog is drawer -->
            <div
              v-else
              class="app-header__close fixed top-0 end-0 lg:hidden flex items-center justify-end p-5 z-10"
            >
              <button
                class="app-dialog__close-button rounded-full w-10 h-10 bg-white text-black flex items-center justify-center"
                @click="isOpen = false"
              >
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-4 h-4"
                >
                  <path
                    d="M15.4409 1.02539L0.936768 15.5295"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M0.936768 1.02539L15.4409 15.5295"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <div
              v-if="drawer && $slots['drawer-bg']"
              class="app-dialog__drawer-bg grow flex flex-col items-stretch justify-stretch"
            >
              <slot name="drawer-bg"></slot>
            </div>

            <div
              class="flex flex-col lg:min-h-full w-full mx-auto items-center justify-center lg:py-8"
              :class="[
                props.big ? 'lg:max-w-[94.375rem]' : 'lg:max-w-[72.5rem]',
                {
                  'min-h-[calc(100dvh_-_4.125rem)]': !props.drawer,
                  '-mt-10': props.drawer,
                },
              ]"
            >
              <component
                :is="isMobile ? 'div' : HeadlessDialogPanel"
                class="app-dialog__panel relative w-full flex flex-col items-stretch justify-stretch overflow-hidden lg:rounded-b-[2.5rem] lg:rounded-s-[2.5rem]"
                :class="[
                  props.big ? 'lg:min-h-[50rem]' : 'lg:min-h-[45rem]',
                  props.tonal ? currentPalette.bg : 'bg-white',
                  { 'min-h-[calc(100dvh_-_4.125rem)]': !props.drawer },
                  { 'rounded-t-[2.5rem]': props.drawer },
                ]"
              >
                <button
                  class="app-dialog__close app-dialog__close--desktop absolute top-0 end-0 w-16 h-16 hidden lg:flex items-center justify-center"
                  title="Zamknij"
                  @click="isOpen = false"
                >
                  <Icon name="ns:close-big" />
                </button>

                <div
                  class="app-dialog__panel-wrapper grow h-full flex items-stretch justify-stretch"
                >
                  <div
                    v-if="$slots.side"
                    class="app-dialog__side basis-[45rem] w-[45rem] relative bg-black/5 hidden lg:block"
                  >
                    <slot name="side"></slot>
                  </div>

                  <div
                    class="app-dialog__content lg:w-[27.5rem] grow flex flex-col items-stretch justify-stretch"
                  >
                    <div
                      class="app-dialog__subheader"
                      v-if="$slots['subheader'] && isMobile"
                    >
                      <div
                        class="app-dialog__subheader-container lg:px-20 h-14 flex flex-col items-stretch justify-center"
                        :class="[props.wide ? 'px-5' : 'px-12']"
                      >
                        <div
                          class="app-dialog__subheader-wrapper flex items-center justify-stretch gap-4"
                        >
                          <slot name="subheader"></slot>
                        </div>
                      </div>
                    </div>

                    <div
                      class="app-dialog__container py-5 pb-10 lg:py-[3.75rem] grow lg:px-20 flex flex-col items-stretch justify-stretch"
                      :class="[
                        drawer ? '!px-5 !py-10' : props.wide ? 'px-5' : 'px-12',
                      ]"
                    >
                      <HeadlessDialogTitle
                        v-if="props.title"
                        as="h2"
                        class="text-2xl font-bold mb-1"
                        :class="[{ 'text-center': drawer }]"
                      >
                        {{ props.title }}
                      </HeadlessDialogTitle>

                      <HeadlessDialogDescription as="div" class="grow">
                        <slot></slot>
                      </HeadlessDialogDescription>
                    </div>
                  </div>
                </div>
              </component>
            </div>
          </component>
        </HeadlessTransitionChild>
      </HeadlessDialog>
    </HeadlessTransitionRoot>
  </Teleport>
</template>
